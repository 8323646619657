

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';


import { CustomCost, DuryRate } from '@/entities/custom-cost/custom-cost';
import { CalculatorService } from '@/services/calculator';


const MODULE_NAME = 'calculator';

@Module({ namespaced: true })
class CalculatorStore {
    public static readonly getModule = () => ({
        [MODULE_NAME]: CalculatorStore as any as VuexModule,
    })

    private customCost!: CustomCost;

    private duryRate!: DuryRate;

    public get CustomCost() {
        return this.customCost;
    }
    public get DuryRate() {
        return this.duryRate;
    }

    // ------------------------------------------------------------------------
    // Getters
    // ------------------------------------------------------------------------

    // ------------------------------------------------------------------------
    // Actions
    // ------------------------------------------------------------------------
    @Action({ commit: 'setDutylist' })
    public async fetchDutylist() {
        const result = await CalculatorService.getDutylist();

        return result;
    }

      @Action({ commit: 'setCustomCost' })
    public async fetchCalculate(data:any) {
        const result = await CalculatorService.calculate(data);

        return result;
    }


    // ------------------------------------------------------------------------
    // Mutations
    // ------------------------------------------------------------------------
    @Mutation
    private setDutylist(values: DuryRate) {
        this.duryRate = values;
    }

    @Mutation
    private setCustomCost(values: CustomCost) {
        this.customCost = values;
    }
}


export {
    CalculatorStore,
};
